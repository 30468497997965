<template>
  <div>
    <div class="uk-inline id8-underpath">
      <span class="uk-form-icon"></span>
      <input class="uk-input uk-form-small uk-form-width-large" type="text" v-model="ctxCandidateString" />
      <span class="uk-form-icon uk-form-icon-flip"></span>
    </div>

    <div class="uk-inline id8-overpath">
      <a class="uk-form-icon" uk-icon="icon: close" @click="resetSearch"></a>
      <input
        class="uk-input uk-form-small uk-form-width-large"
        type="text"
        placeholder="Prop filter string here!"
        v-model:lazy="rawSearchString"
        @keyup.up="cycleCandidate(false)"
        @keyup.down="cycleCandidate(true)"
        @keyup.enter="selectCtxProp"
      />
      <a class="uk-form-icon uk-form-icon-flip" uk-icon="icon: forward"></a>
    </div>
    <div class="id8-scroller">
      <div v-for="record in scopedRecords" :key="record.PropID">
        <span v-text="record.PropID"></span>
        <span v-text="record.Nickname"></span>
      </div>
    </div>
  </div>
</template>


<script>
const conv_src = '１２３４５６７８９０①②③④⑤⑥⑦⑧⑨⓪一二三四五六七八九零ＡＢＣＤＥＦＧＨＩＪＫＬＭＮＯＰＱＲＳＴＵＶＷＸＹＺａｂｃｄｅｆｇｈｉｊｋｌｍｎｏｐｑｒｓｔｕｖｗｘｙｚ〜＠＃＄％＾＆＊ー＋＝？！?!　（）()\'’,.:;-=\r\n\t';
const conv_dst = '123456789012345678901234567890abcdefghijklmnopqrstuvwxyzabcdefghijklmnopqrstuvwxyz~@#$%^&*-+=                    ';

import axios from "axios";

export default {
  data: function() {
    return {
      rawSearchString: "",
      fixedSearchString: null,
      rawRecords: null,
      fetchCanceller: null,
      ctxObjProp: null,
      ctxCandidatePos: 0,
      ctxScopeRange: 5
    };
  },

  mounted: function() {
    this.retrieveRecordList();
  },

  watch: {
    rawSearchString: function() {
      this.ctxCandidatePos = 0;
    }
  },

  computed: {
    scopedRecords: function() {
      return this.filteredRecords.slice(this.ctxCandidatePos, this.ctxCandidatePos + this.ctxScopeRange);
    },

    filteredRecords: function() {
      const normalizedSearchString = [ ...this.rawSearchString ].map(ch => {
          const index = conv_src.indexOf(ch);
          return index < 0 ? ch : conv_dst[index];
        }).join('').toLowerCase();
      
      return this.rawRecords
        ? this.rawRecords.filter(record =>
            record.SearchIndex
              ? record.SearchIndex.startsWith(normalizedSearchString)
              : false
          )
        : [];
    },

    ctxCandidateString: function() {
      return (this.rawSearchString && this.filteredRecords.length) ? this.filteredRecords[this.ctxCandidatePos].SearchIndex : '';
    }
  },

  methods: {
    selectCtxProp: function() {
      // console.log(`PropID: ${propID}, ${this.rawRecords.find(entry => entry.PropID == propID)}`);
      this.$emit('ctxPropUpdated', this.ctxObjProp);
    },

    cycleCandidate: function(dir) {
      // 'dir = true' means next candidate were asked.
      dir ? this.ctxCandidatePos++ : this.ctxCandidatePos--;

      this.ctxCandidatePos = this.ctxCandidatePos < 0 ? this.filteredRecords.length - 1 : this.ctxCandidatePos;
      this.ctxCandidatePos = this.ctxCandidatePos > (this.filteredRecords.length - 1) ? 0 : this.ctxCandidatePos;
      
      this.ctxObjProp = this.filteredRecords[this.ctxCandidatePos];

      console.log(this.ctxCandidatePos)      
    },

    resetSearch: function() {
      if (this.rawSearchString.length) {
        this.rawSearchString = this.fixedSearchString = "";
        this.retrieveRecordList();
      }
    },

    retrieveRecordList: function() {
      const apiUri =
        "https://asia-northeast1-eajweb-158909.cloudfunctions.net/quickPropMatchIndex_tokyo";

      this.fetchCanceller ? this.fetchCanceller.cancel() : null;
      this.fetchCanceller = axios.CancelToken.source();

      // console.time(`SubSearch for ${this.record_id}`);

      this.rawRecords = null;

      const queryParam = {};

      const jsonParams = {
        params: queryParam,
        cancelToken: this.fetchCanceller.token
      };

      axios
        .post(apiUri, queryParam, { cancelToken: this.fetchCanceller.token })
        .then(resp => {
          this.rawRecords = resp.data;
        })
        .catch(e => {
          if (!axios.isCancel(e)) {
            console.log("WebAPI GET Failed...", e);
            this.rawRecords = [];
          } else console.log("WebAPI GET Cancelled...", e);
        });
      // .finally(() => console.timeEnd(`SubSearch for ${this.record_id}`));
    }
  }
};
</script>


<style scoped lang="scss">
.records {
  margin-top: 16px;

  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 5px;

  border-left: 75px solid rgba(0, 0, 0, 0.08);

  background: rgba(0, 0, 0, 0.05);

  .record {
    &:not(:last-of-type) {
      border: none;
    }

    padding-top: 0.2em;
    padding-bottom: 0.4em;
  }
}

.id8-underpath {
  position: absolute;
}

.id8-overpath {
  input {
    background: transparent;
  }
}

</style>