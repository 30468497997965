<template>
  <div class="subSection" :class="{expanded: isExpanded}">
      <button class="uk-button uk-button-default uk-button-large">+ Submission</button>
      <button class="uk-button uk-button-default uk-button-large">+ Material</button>
      <button class="uk-button uk-button-default uk-button-large">+ Contract</button>
  </div>
</template>


<script>
import axios from "axios";

export default {
  data: function() {
    return {
      isExpanded: false,
      rawRecords: null,
      fetchCanceller: null
    };
  },

  props: [ "record_id" ],

  computed: {
  },

  watch: {
  },

  methods: {
    retrieveRecordList: function() {
      const apiUri =
        "https://asia-northeast1-eajweb-158909.cloudfunctions.net/enumRecords";

      this.fetchCanceller ? this.fetchCanceller.cancel() : null;
      this.fetchCanceller = axios.CancelToken.source();

      // console.time(`SubSearch for ${this.record_id}`);

      this.rawRecords = null;

      const queryParam = {
        target: "CONT",
        id: this.record_id,
        limit: 100,
        offset: 0
      };

      const jsonParams = {
        params: queryParam,
        cancelToken: this.fetchCanceller.token
      };

      axios
        .post(apiUri, queryParam, { cancelToken: this.fetchCanceller.token })
        .then(resp => {
          this.rawRecords = resp.data;
        })
        .catch(e => {
          if (!axios.isCancel(e)) {
            console.log("WebAPI GET Failed...", e);
            this.rawRecords = [];
          } else console.log("WebAPI GET Cancelled...", e);
        });
      // .finally(() => console.timeEnd(`SubSearch for ${this.record_id}`));
    }
  }
};
</script>


<style scoped lang="scss">
.subSection {
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.subSectionHeader {
  font-size: 0.9rem;
  margin-top: 1.1em;
  padding-top: 0.1em;
  padding-bottom: 0.1em;
  padding-left: 0.25em;
  padding-right: 0.25em;
  background: rgba(155, 38, 11, 0.4);
  color: white;
  font-weight: bold;
  border-radius: 4px 4px 0 0;
  cursor: pointer;

  transition:background 0.4s ease;

  .expanded & {
    background: rgba(155, 38, 11, 0.8);
  }

  &:hover {
    background: rgba(143, 105, 97, 0.8);
  }
}

.subSectionFooter {
  height: 0.2rem;
  background: rgba(155, 38, 11, 0.2);
  color: white;
  border-radius: 0 0 4px 4px;

  transition:background 0.4s ease;

  .expanded & {
    background: rgba(155, 38, 11, 0.8);
  }
}

.records {
  height: 0;
  opacity: 0;
  overflow: hidden;

  transition:opacity 0.4s ease;

  .expanded & {
    height: auto;
    opacity: 1.0;
  }

  padding-right: 0.4em;

  border-left: 25px solid rgba(0, 0, 0, 0.08);

  background: rgba(0, 0, 0, 0.05);

  .record {
    &:not(:last-of-type) {
      border: none;
    }

    padding-top: 0.2em;
    padding-bottom: 0.4em;
  }
}
</style>