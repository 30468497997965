<template>
  <div class="subSection" :class="{expanded: isExpanded}">
    <div class="subSectionHeader" @click="isExpanded =! isExpanded">
      <span class="icon" v-if="isExpanded"><i class="fas fa-folder-open"></i></span>
      <span class="icon" v-else><i class="fas fa-folder"></i></span>
      <span v-text="`Contracts (${show_count})`"></span>
    </div>

    <div class="records">
      <div class="record" v-if="!rawRecords">
        <span class="icon"><i class="fas fa-spinner fa-pulse"></i></span>
      </div>

      <div class="record" v-for="record in rawRecords" :key="record.ContractBaseID">
        <div class="tag-parade">
          <div class="field is-grouped is-grouped-multiline">
            <div class="control" v-if="record.ContractBaseID">
              <span class="tags has-addons are-small">
                <!-- <span class="tag contid" @click="openEditor(record.ContractBaseID)">ContID</span> -->
                <span class="tag contid">ContID</span>
                <span
                  class="tag"
                  v-text="record.ContractBaseID"
                  @click="copyText(record.ContractBaseID)" :uk-tooltip="'Click to copy id#'"
                ></span>
              </span>
            </div>

            <div class="control" v-if="record['Language_Target']">
              <span class="tags has-addons are-small">
                <span class="tag lang">Lang</span>
                <span class="tag" v-text="record['Language_Target']"></span>
              </span>
            </div>

            <div class="control" v-if="record['JPubName-E']">
              <span class="tags has-addons are-small">
                <span class="tag jpub">Licensee</span>
                <span class="tag" v-text="record['JPubName-E']" @click="copyText(record['JPubName-E'])" :uk-tooltip="'Click to copy Licensee'"></span>
              </span>
            </div>

            <div class="control" v-if="record['JPubTarget-E']">
              <span class="tags has-addons are-small">
                <span class="tag midpub">Licensee_2</span>
                <span class="tag" v-text="record['JPubTarget-E']"></span>
              </span>
            </div>
            
            <div class="control" v-if="record.BookID && record.Contracts > 0">
              <span class="tags has-addons are-small">
                <span class="tag cont">Contracts</span>
                <span class="tag" v-text="record.Contracts"></span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="subSectionFooter"></div>
  </div>
</template>


<script>
import axios from "axios";

export default {
  data: function() {
    return {
      isExpanded: false,
      rawRecords: null,
      fetchCanceller: null
    };
  },

  props: [ "record_id", "show_count" ],

  computed: {
    sophis: function() {
      return `${this.message} is is is [${record_id}]?`;
    }
  },

  // mounted: function () {
  //   this.retrieveRecordList();
  // },

  watch: {
    isExpanded: function() {
      this.rawRecords ? null : this.retrieveRecordList();
    },

    // record_id: function() {
    //   console.log("watch in component!");
    //   this.retrieveBookList();
    // }
  },

  methods: {
    copyText: async function(contID) {
      await navigator.clipboard.writeText(contID);
      console.log(`Copied text ${contID} to clipboard.`);
    },

    openEditor: function(target_id) {
      this.$emit("child-open-editor", target_id);
    },

    retrieveRecordList: function() {
      const apiUri =
        "https://asia-northeast1-eajweb-158909.cloudfunctions.net/enumRecords";

      this.fetchCanceller ? this.fetchCanceller.cancel() : null;
      this.fetchCanceller = axios.CancelToken.source();

      // console.time(`SubSearch for ${this.record_id}`);

      this.rawRecords = null;

      const queryParam = {
        target: "CONT",
        id: this.record_id,
        limit: 100,
        offset: 0
      };

      const jsonParams = {
        params: queryParam,
        cancelToken: this.fetchCanceller.token
      };

      axios
        .post(apiUri, queryParam, { cancelToken: this.fetchCanceller.token })
        .then(resp => {
          this.rawRecords = resp.data;
        })
        .catch(e => {
          if (!axios.isCancel(e)) {
            console.log("WebAPI GET Failed...", e);
            this.rawRecords = [];
          } else console.log("WebAPI GET Cancelled...", e);
        });
      // .finally(() => console.timeEnd(`SubSearch for ${this.record_id}`));
    }
  }
};
</script>


<style scoped lang="scss">
.subSection {
  margin-left: 1rem;
  margin-right: 1rem;
}

.subSectionHeader {
  font-size: 0.9rem;
  margin-top: 1.1em;
  padding-top: 0.1em;
  padding-bottom: 0.1em;
  padding-left: 0.25em;
  padding-right: 0.25em;
  background: rgba(155, 38, 11, 0.4);
  color: white;
  font-weight: bold;
  border-radius: 4px 4px 0 0;
  cursor: pointer;

  transition:background 0.4s ease;

  .expanded & {
    background: rgba(155, 38, 11, 0.8);
  }

  &:hover {
    background: rgba(143, 105, 97, 0.8);
  }
}

.subSectionFooter {
  height: 0.2rem;
  background: rgba(155, 38, 11, 0.2);
  color: white;
  border-radius: 0 0 4px 4px;

  transition:background 0.4s ease;

  .expanded & {
    background: rgba(155, 38, 11, 0.8);
  }
}

.records {
  height: 0;
  opacity: 0;
  overflow: hidden;

  transition:opacity 0.4s ease;

  .expanded & {
    height: auto;
    opacity: 1.0;
  }

  padding-right: 0.4em;

  border-left: 25px solid rgba(0, 0, 0, 0.08);

  background: rgba(0, 0, 0, 0.05);

  .record {
    &:not(:last-of-type) {
      border: none;
    }

    padding-top: 0.2em;
    padding-bottom: 0.4em;
  }
}
</style>