export default class {

    constructor(uri = null) {
        this._uriRoot = uri || location.pathname;
        this.data = null;

        this.init();
    }

    init() {
        try {
            const rawData = window.localStorage.getItem(this._uriRoot);
            this.data = rawData ? JSON.parse(rawData) : {};
        }
        catch (e) {
            return e;
        }
    }

    resync() {
        this.init();
    }

    removeAllItems() {
        try {
            window.localStorage.removeItem(this._uriRoot);
            this.data = {};
        }
        catch (e) {
            return e;
        }
    }

    setItem(key, value) {
        this.data[key] = value;

        try {
            window.localStorage.setItem(this._uriRoot, JSON.stringify(this.data));
        }
        catch (e) {
            return e;
        }
    }

    getItem(key) {
        return key ? this.data[key] : this.data;
    }

    set uriRoot(value) {
        this._uriRoot = value;
        this.resync();
    }
}