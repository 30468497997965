<template>
  <div class="records" v-if="processedRecords">
    <div
      class="record"
      v-for="record in processedRecords"
      :class="{ 'is-fullfts': record.FtsHits }"
      :key="record.BookID"
      :ref="record.BookID"
      @click="ctxSelection=record.BookID"
    >
      <div class="main-title">
        <div>
          <span class="genre" v-text="record.Genre ? record.Genre : 'Other'"></span>
        </div>
        <div>
          <span
            class="title"
            v-text="record.Title"
            :class="{ 'is-emphasize': isFieldHit(record.EvolveKwdHitsJson, 'Title'), 'is-fullfts': record.FtsHits }"
          ></span>
          <span
            class="subtitle"
            :class="{ 'is-emphasize': isFieldHit(record.EvolveKwdHitsJson, 'Subtitle') }"
            v-text="record.Subtitle"
          ></span>
        </div>
      </div>

      <div class="tag-parade">
        <div class="field is-grouped is-grouped-multiline">
          <div class="control" v-if="record.ContractBaseID">
            <span class="tags has-addons are-small">
              <span class="tag contid">ContID</span>
              <span class="tag" v-text="record.ContractBaseID"></span>
            </span>
          </div>

          <div class="control">
            <span
              class="tags has-addons are-medium"
              :class="{ 'is-emphasize': record.UnionField == 'BookID' }"
            >
              <span
                class="tag bookid"
                v-text="record.BookID ? 'BookID' : 'ContID'"
                @click="openEditor(record.BookID, 'BOOK')"
              ></span>
              <span
                class="tag bookval"
                :bookid="record.BookID"
                v-text="record.BookID"
                @click="copyText(record.BookID)"
              ></span>
            </span>
          </div>

          <div class="control">
            <span
              class="tags has-addons are-medium"
              :class="{ 'is-emphasize': isFieldHit(record.EvolveKwdHitsJson, 'Author') }"
            >
              <span class="tag author">Author</span>
              <span class="tag" v-text="record.Author"></span>
            </span>
          </div>
          <div class="control">
            <span
              class="tags has-addons are-medium"
              :class="{ 'is-emphasize': isFieldHit(record.EvolveKwdHitsJson, 'Nickname') }"
            >
              <span class="tag prop">Prop</span>
              <span class="tag" v-text="record.Nickname"></span>
            </span>
          </div>
          <div class="control" v-if="record['JPubName-E']">
            <span class="tags has-addons are-medium">
              <span class="tag jpub">Licensee</span>
              <span class="tag" v-text="record['JPubName-E']"></span>
            </span>
          </div>
          <div class="control" v-if="record['Language_Target']">
            <span class="tags has-addons are-medium">
              <span class="tag lang">Lang</span>
              <span class="tag" v-text="record['Language_Target']"></span>
            </span>
          </div>
          <div class="control" v-if="record['JPubTarget-E']">
            <span class="tags has-addons are-medium">
              <span class="tag midpub">Licensee_2</span>
              <span class="tag" v-text="record['JPubTarget-E']"></span>
            </span>
          </div>
        </div>
      </div>

      <sub-contracts
        v-if="record.Contracts > 0"
        :record_id="record.BookID"
        :show_count="record.Contracts"
        @child-open-editor="openEditor($event, 'CONT')"
      ></sub-contracts>

      <sub-actions v-if="record.isContextSelection" :record_id="record.BookID"></sub-actions>
    </div>

    <span ref="more_records_anchor" v-text="'... read more records.'"></span>
    <sub-actions v-if="!totalRecordCount" :record_id="0"></sub-actions>
  </div>
</template>


<script>
import axios from "axios";

import SubActions from "./subActions.vue";
import SubContracts from "./subContracts.vue";

export default {
  data: function() {
    return {
      rawRecords: null,
      fetchCanceller: null
    };
  },

  props: [
    "ctxCloudEndpoint",
    "ctxSearchTable",
    "fixedSearchString",
    "searchFragments"
  ],

  components: {
    "sub-actions": SubActions,
    "sub-contracts": SubContracts
  },

  mounted: function() {
    this.retrieveBookList();
  },

  computed: {
    processedRecords: function() {
      //return null;
      return (
        this.rawRecords &&
        this.rawRecords.map(rawRecord => {
          const tmp = { ...rawRecord };

          tmp.EvolveKwdHitsJson = JSON.parse(rawRecord.EvolveKwdHits);
          tmp.isContextSelection = rawRecord.BookID == this.ctxSelection;

          return tmp;
        })
      );
    },
    
    ftsRecordCount: function () {
      return this.rawRecords && this.rawRecords[0]
        ? this.rawRecords[0].fts_count
        : 0;
    },

    totalRecordCount: function () {
      return this.rawRecords && this.rawRecords[0]
        ? this.rawRecords[0].full_count
        : 0;
    },

    countIndicator: function () {
      return this.rawRecords
        ? `全ての検索語が含まれるもの ${this.ftsRecordCount} 件 / 総ヒット数 ${this.totalRecordCount} 件`
        : "Search in progress...";
    },
  },

  watch: {
    fixedSearchString: function() {
      console.log("fixedSearchString updated");
      this.retrieveBookList();
    }
  },

  methods: {
    isFieldHit: function(jsonData, fieldName) {
      return (
        jsonData.filter(
          entry => entry.Field.toLowerCase() === fieldName.toLowerCase()
        ).length > 0
      );
    },

    retrieveBookList: function() {
      const apiUri = {
        "gcp-tokyo1": {
          BOOK:
            "https://asia-northeast1-eajweb-158909.cloudfunctions.net/evolvedSearchMappedStepSimplifiedEx_tokyo",
          "SUBSP+Uni5":
            "https://asia-northeast1-eajweb-158909.cloudfunctions.net/evolvedSearchMappedStepSimplifiedEx_tokyo",
          "SUBSP+UNI40":
            "https://asia-northeast1-eajweb-158909.cloudfunctions.net/evolvedSearchMappedStepSimplifiedEx_tokyo",
          "SUBSP2+UNI40":
            "https://asia-northeast1-eajweb-158909.cloudfunctions.net/evolvedSearchMappedStepSimplifiedEx_tokyo",
          "SUBSP+Smpl5":
            "https://asia-northeast1-eajweb-158909.cloudfunctions.net/evolvedSearchMappedStepSimplifiedEx_tokyo",
          "SUBSP+Smpl40":
            "https://asia-northeast1-eajweb-158909.cloudfunctions.net/evolvedSearchMappedStepSimplifiedEx_tokyo",
          "SUBSP2+Smpl40":
            "https://asia-northeast1-eajweb-158909.cloudfunctions.net/evolvedSearchMappedStepSimplifiedEx_tokyo",
          "NOSP+Smpl40":
            "https://asia-northeast1-eajweb-158909.cloudfunctions.net/evolvedSearchMappedStepSimplifiedEx_tokyo",
          "NOSP2+Smpl40":
            "https://asia-northeast1-eajweb-158909.cloudfunctions.net/evolvedSearchMappedStepSimplifiedEx_tokyo",
          "MAP+Smpl100":
            "https://asia-northeast1-eajweb-158909.cloudfunctions.net/evolvedSearchMappedStepSimplifiedEx_tokyo",
          "MAP2+Smpl100":
            "https://asia-northeast1-eajweb-158909.cloudfunctions.net/evolvedSearchMappedStepSimplifiedEx_tokyo",
          SingleWordTitle:
            "https://asia-northeast1-eajweb-158909.cloudfunctions.net/evolvedSearchMappedStepSimplifiedEx_tokyo"
        },
        "gcp-oregon1": {
          BOOK:
            "https://asia-northeast1-eajweb-158909.cloudfunctions.net/evolvedSearchMappedStepSimplifiedEx_oregon",
          "SUBSP+Uni5":
            "https://asia-northeast1-eajweb-158909.cloudfunctions.net/evolvedSearchMappedStepSimplifiedEx_oregon",
          "SUBSP+UNI40":
            "https://asia-northeast1-eajweb-158909.cloudfunctions.net/evolvedSearchMappedStepSimplifiedEx_oregon",
          "SUBSP2+UNI40":
            "https://asia-northeast1-eajweb-158909.cloudfunctions.net/evolvedSearchMappedStepSimplifiedEx_oregon",
          "SUBSP+Smpl5":
            "https://asia-northeast1-eajweb-158909.cloudfunctions.net/evolvedSearchMappedStepSimplifiedEx_oregon",
          "SUBSP+Smpl40":
            "https://asia-northeast1-eajweb-158909.cloudfunctions.net/evolvedSearchMappedStepSimplifiedEx_oregon",
          "SUBSP2+Smpl40":
            "https://asia-northeast1-eajweb-158909.cloudfunctions.net/evolvedSearchMappedStepSimplifiedEx_oregon",
          "NOSP+Smpl40":
            "https://asia-northeast1-eajweb-158909.cloudfunctions.net/evolvedSearchMappedStepSimplifiedEx_oregon",
          "NOSP2+Smpl40":
            "https://asia-northeast1-eajweb-158909.cloudfunctions.net/evolvedSearchMappedStepSimplifiedEx_oregon",
          "MAP+Smpl100":
            "https://asia-northeast1-eajweb-158909.cloudfunctions.net/evolvedSearchMappedStepSimplifiedEx_oregon",
          "MAP2+Smpl100":
            "https://asia-northeast1-eajweb-158909.cloudfunctions.net/evolvedSearchMappedStepSimplifiedEx_oregon",
          SingleWordTitle:
            "https://asia-northeast1-eajweb-158909.cloudfunctions.net/evolvedSearchMappedStepSimplifiedEx_oregon"
        }
      };

      this.fetchCanceller ? this.fetchCanceller.cancel() : null;
      this.fetchCanceller = axios.CancelToken.source();

      console.time("SearchQuery");

      this.rawRecords = null;

      const queryParam = {
        target: this.ctxSearchTable,
        word: this.fixedSearchString,
        fragments: this.searchFragments[0],
        fragmentsInclude: this.searchFragments[0],
        fragmentsExclude: this.searchFragments[1],
        fragmentsMandatory: this.searchFragments[2],
        limit: 100,
        offset: 0
      };

      console.log("Includes:", this.searchFragments[0].join(", "));
      console.log("Excludes:", this.searchFragments[1].join(", "));
      console.log("Mandatories:", this.searchFragments[2].join(", "));

      const jsonParams = {
        params: queryParam,
        cancelToken: this.fetchCanceller.token
      };

      axios
        .post(apiUri[this.ctxCloudEndpoint][this.ctxSearchTable], queryParam, {
          cancelToken: this.fetchCanceller.token
        })
        .then(resp => {
          this.rawRecords = resp.data;
        })
        .catch(e => {
          if (!axios.isCancel(e)) {
            console.log("WebAPI GET Failed...", e);
            this.rawRecords = [];
          } else console.log("WebAPI GET Cancelled...", e);
        })
        .finally(() => console.timeEnd("SearchQuery"));
    }
  }
};
</script>


<style scoped lang="scss">
.records {
  .is-emphasize {
    border-bottom: 1px solid rgba(250, 20, 20, 0.6);
  }

  .record {
    // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;

    padding-top: 0.8rem;
    padding-bottom: 1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    border-right: 0px;

    overflow: hidden;

    // &:hover {
    //   background-color: rgba(155, 155, 255, 0.15);
    // }

    &:not(:last-of-type) {
      border-style: solid;
      border-image: linear-gradient(
          to right,
          white 0%,
          lightgray 8%,
          lightgray 90%,
          white 100%
        )
        1/0 0 1px 0;
    }

    .main-title {
      padding-bottom: 0.6rem;
      line-height: 1;

      .id {
        vertical-align: middle;
      }

      .genre {
        vertical-align: middle;
        font-size: 0.8rem;
        font-weight: bold;
        color: darkslategrey;
      }

      .title {
        vertical-align: middle;
        font-size: 1.2rem;
        color: gray;
      }

      .subtitle {
        vertical-align: middle;
        font-size: 0.85rem;
        color: gray;
        margin-left: 1rem;
      }
    }

    &.is-fullfts {
      background: linear-gradient(
        105deg,
        rgba(6, 99, 171, 0.1) 0%,
        rgba(34, 112, 196, 0.1) 15%,
        rgba(0, 123, 255, 0) 100%
      );

      .title {
        font-weight: bold;
        color: rgb(184, 19, 13);
      }
    }

    .tag-parade {
      // padding-left: 0.4rem;

      input[type="text"][disabled] {
        background: transparent;
        color: black;
        cursor: default;
        border: none;

        padding-left: 0.25em;
        padding-right: 0.25em;

        text-align: right;

        font-size: 1rem;
        width: 4.2em;
      }

      .tag {
        margin-bottom: 0.2rem;

        &:hover {
          background-color: rgba(67, 20, 80, 0.6);
        }
      }

      .bias {
        color: white;
        background-color: rgba(89, 57, 175, 0.8);
      }

      .contid {
        color: white;
        background-color: rgba(155, 38, 11, 0.8);
      }

      .bookid {
        color: white;
        background-color: rgba(36, 36, 36, 0.8);
      }

      .author {
        background-color: rgba(50, 152, 220, 0.4);
      }

      .prop {
        background-color: rgba(141, 170, 104, 0.4);
      }

      .jpub {
        background-color: rgba(141, 70, 54, 0.4);
      }

      .midpub {
        background-color: rgba(212, 136, 219, 0.4);
      }

      .lang {
        background-color: rgba(151, 135, 64, 0.4);
      }

      .key {
        background-color: rgba(141, 70, 54, 0.4);
      }

      .cont {
        color: white;
        background-color: rgba(155, 38, 11, 0.8);
      }

      .hits {
        color: white;
        background-color: rgba(25, 151, 67, 0.8);
      }

      .tag .entry {
        margin-right: 0.4em;

        padding-right: 0.4em;
        padding-left: 0.4em;

        border-radius: 4px;
        border: 1px dashed;
      }
    }
  }
}
</style>