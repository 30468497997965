<template>
  <div class="subSection">
    <button
      class="uk-button uk-button-default uk-button-large"
      @click="addBookInStory=!addBookInStory"
    >+ Book</button>

    <div class="uk-card uk-card-default" v-if="addBookInStory">
      <div class="uk-card-body">
        <ul uk-accordion>
          <li class="uk-open">
            <a class="uk-accordion-title" href="#">1. Proprietor</a>
            <div class="uk-accordion-content">
              <sub-searchprop @ctxPropUpdated="onCtxPropUpdated"></sub-searchprop>
            </div>
          </li>
          <li>
            <a class="uk-accordion-title" href="#">2. Book Inforamtion</a>
            <div class="uk-accordion-content">
              <step-bookrecord :obj_prop="ctxObjProp"></step-bookrecord>
            </div>
          </li>
          <li>
            <a class="uk-accordion-title" href="#">3.</a>
            <div class="uk-accordion-content">
              <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat proident.</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import SubSearchProp from "./subSearchProp.vue";
import StepBookRecord from "./stepBookRecord.vue";


export default {
  data: function() {
    return {
      addBookInStory: false,
      ctxObjProp: null,
      isExpanded: false,
      rawRecords: null,
      rawTableSchema: null,
      fetchCanceller: null
    };
  },

  props: ["record_id"],

  components: {
    "sub-searchprop": SubSearchProp,
    "step-bookrecord": StepBookRecord
  },

  computed: {
  },

  watch: {},

  methods: {
    onCtxPropUpdated: function(objProp) {
      console.log(`Context Prop Obj: ${JSON.stringify(objProp, null, 2)}`);
      
      objProp.PropNickname = objProp.Nickname;
      this.ctxObjProp = objProp;
    }
  }
};
</script>


<style scoped lang="scss">
.subSection {
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
  // margin-left: 1rem;
  // margin-right: 1rem;
}

.subSectionHeader {
  font-size: 0.9rem;
  margin-top: 1.1em;
  padding-top: 0.1em;
  padding-bottom: 0.1em;
  padding-left: 0.25em;
  padding-right: 0.25em;
  background: rgba(155, 38, 11, 0.4);
  color: white;
  font-weight: bold;
  border-radius: 4px 4px 0 0;
  cursor: pointer;

  transition: background 0.4s ease;

  .expanded & {
    background: rgba(155, 38, 11, 0.8);
  }

  &:hover {
    background: rgba(143, 105, 97, 0.8);
  }
}

.subSectionFooter {
  height: 0.2rem;
  background: rgba(155, 38, 11, 0.2);
  color: white;
  border-radius: 0 0 4px 4px;

  transition: background 0.4s ease;

  .expanded & {
    background: rgba(155, 38, 11, 0.8);
  }
}

.records {
  height: 0;
  opacity: 0;
  overflow: hidden;

  transition: opacity 0.4s ease;

  .expanded & {
    height: auto;
    opacity: 1;
  }

  padding-right: 0.4em;

  border-left: 25px solid rgba(0, 0, 0, 0.08);

  background: rgba(0, 0, 0, 0.05);

  .record {
    &:not(:last-of-type) {
      border: none;
    }

    padding-top: 0.2em;
    padding-bottom: 0.4em;
  }
}
</style>