<template>
    <div class="uk-grid">
      <div class="uk-grid-column-small" v-for="entry in filteredSchema" :key="entry.column_name">
        <label
          class="uk-form-label"
          v-text="`${entry.column_name} (${entry.data_type}/${entry.character_maximum_length})`"
        ></label>
        <div class="uk-form-controls">
          <textarea
            class="uk-textarea"
            v-if="entry.data_type == 'text'"
            :disabled="!entry.editable"
          ></textarea>
          <input
            class="uk-text uk-form-large"
            v-else
            :class="{'uk-form-width-medium': entry.character_maximum_length < 51, 'uk-form-width-large': entry.character_maximum_length > 254}"
            :disabled="!entry.editable"
          />
        </div>
      </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
  data: function() {
    return {
      addBookInStory: false,
      isExpanded: false,
      rawRecords: null,
      rawTableSchema: null,
      fetchCanceller: null,
      defaultFieldSettings: {
        Title: { editable: true, showup: true },
        Subtitle: { editable: true, showup: true },
        Author: { editable: true, showup: true },
        PropID: { editable: false, showup: true },
        PropNickname: { editable: false, showup: true },
        Publisher: { editable: true, showup: true },
        Review: { editable: true, showup: true },
        PriorityBook: { editable: true, showup: true },
        Memo: { editable: true, showup: true },
        MemoNew: { editable: true, showup: true }
      }
    };
  },

  props: ["obj_prop"],

  components: {
  },

  mounted: function() {
    this.retrieveTableSchema('_BookBase');
  },

  computed: {
    dataInjectedSchema: function() {
      return this.mergedSchema.find(entry => entry.column_name.toLowerCase() == 'PropID'.toLowerCase())
    },

    mergedSchema: function() {
      return this.rawTableSchema
        ? this.rawTableSchema.map(fields => {
            return {
              ...fields,
              ...(fields.column_name in this.defaultFieldSettings
                ? this.defaultFieldSettings[fields.column_name]
                : { editable: false, showup: false })
            };
          })
        : [];
    },

    filteredSchema: function() {
      return this.mergedSchema.filter(entry => entry.showup);
    }
  },

  watch: {},

  methods: {
    retrieveTableSchema: async function(tableName) {
      const apiUri =
        "https://asia-northeast1-eajweb-158909.cloudfunctions.net/getTableSchema_tokyo";

      this.rawTableSchema = null;

      const queryParam = {
        target: "_BookBase"
      };

      const oprawTableSchema = await axios
        .post(apiUri, queryParam)
        .catch(e => console.error(e));

      this.rawTableSchema = oprawTableSchema.data;
    }

  }
};
</script>


<style scoped lang="scss">
.subSection {
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
  // margin-left: 1rem;
  // margin-right: 1rem;
}

.subSectionHeader {
  font-size: 0.9rem;
  margin-top: 1.1em;
  padding-top: 0.1em;
  padding-bottom: 0.1em;
  padding-left: 0.25em;
  padding-right: 0.25em;
  background: rgba(155, 38, 11, 0.4);
  color: white;
  font-weight: bold;
  border-radius: 4px 4px 0 0;
  cursor: pointer;

  transition: background 0.4s ease;

  .expanded & {
    background: rgba(155, 38, 11, 0.8);
  }

  &:hover {
    background: rgba(143, 105, 97, 0.8);
  }
}

.subSectionFooter {
  height: 0.2rem;
  background: rgba(155, 38, 11, 0.2);
  color: white;
  border-radius: 0 0 4px 4px;

  transition: background 0.4s ease;

  .expanded & {
    background: rgba(155, 38, 11, 0.8);
  }
}

.records {
  height: 0;
  opacity: 0;
  overflow: hidden;

  transition: opacity 0.4s ease;

  .expanded & {
    height: auto;
    opacity: 1;
  }

  padding-right: 0.4em;

  border-left: 25px solid rgba(0, 0, 0, 0.08);

  background: rgba(0, 0, 0, 0.05);

  .record {
    &:not(:last-of-type) {
      border: none;
    }

    padding-top: 0.2em;
    padding-bottom: 0.4em;
  }
}
</style>