import "babel-polyfill";

// Firebase related components.
// import * as firebase from "firebase/app";
// import "firebase/auth";
// import "firebase/functions";

import firebase from 'firebase/app';
import 'firebase/auth'
import 'firebase/functions'

import * as firebaseui from 'firebaseui'
import "firebaseui/dist/firebaseui.css"

const firebaseConfig = {
  apiKey: "AIzaSyArgf70Yd6VN5ZpZsI25pYGxqgrMl26xHQ",
  authDomain: "eajweb-158909.firebaseapp.com",
  databaseURL: "https://eajweb-158909.firebaseio.com",
  projectId: "eajweb-158909",
  storageBucket: "eajweb-158909.appspot.com",
  messagingSenderId: "438686250481",
  appId: "1:438686250481:web:2b9f89e9fb551c99"
};

// Initialize Firebase
firebase.default.initializeApp(firebaseConfig);


// const globalSignOut = () => {
//   firebase.auth().signOut()
//     .then(() => window.location = window.location.href);
// };

// Initialize UIKit
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";

import 'uikit/dist/css/uikit.eaj-rightsdb.css'
import 'uikit/dist/css/uikit.eaj-rightsdb.min.css'
// import 'uikit/dist/css/uikit.css'
// import 'uikit/dist/css/uikit.min.css'

UIkit.use(Icons);
// UIkit.notification('hiyas');


// Initialize Vue based page components.
import Vue from "vue";
import axios from "axios";
import IntersectionObserver from "intersection-observer";

import SubActions from "/components/subActions.vue";
import SubContracts from "/components/subContracts.vue";
import SubBooks from "/components/subBooks.vue";
import StreamNewBook from "/components/streamNewBook.vue";

import "./index.scss";
import "./common.scss";

import LSM from "./LocalStorageManager";

const CloudEndpoints = ["gcp-tokyo1", "gcp-oregon1", "gcp-devtest"];
const CloudEndpointTips = {
  "gcp-tokyo1": "gcp-tokyo1 (通常時用サーバ)",
  "gcp-oregon1": "gcp-oregon1 (緊急時用サーバ)",
  "gcp-devtest": "gcp-devtest (開発用サーバ)"
};

const DefaultSearchPresets = {
  searchTarget: "BOOK",
  searchString: "",
  cloudEndpoint: CloudEndpoints[0]
};

const GenreIndexList = {
  longTitles: [
    "",
    "adult nonfiction",
    "adult fiction",
    "children",
    "business",
    "ya",
    "computer",
    "sports",
    "music",
    "crossover",
    "middle grade"
  ],
  labelTitle: [" ", "NF", "F", "CH", "BU", "YA", "IT", "SP", "MU", "YA", "YA"]
};

// const recordPos_observer = new IntersectionObserver( (entries) => {
//   if (entries[0].intersectionRatio > 0) {
//     console.log('****** INTERSECT - READ MORE LINES NOW ******', entries[0].intersectionRatio);

//     vm.onDemandLoadExtraRecords();
//   }
// }, { threshold: [.5] });

const conv_src =
  "１２３４５６７８９０①②③④⑤⑥⑦⑧⑨⓪一二三四五六七八九零ＡＢＣＤＥＦＧＨＩＪＫＬＭＮＯＰＱＲＳＴＵＶＷＸＹＺａｂｃｄｅｆｇｈｉｊｋｌｍｎｏｐｑｒｓｔｕｖｗｘｙｚ〜＠＃＄％＾＆＊ー＋＝？！?!　（）()',.:;-=\r\n\t";
const conv_dst =
  "123456789012345678901234567890abcdefghijklmnopqrstuvwxyzabcdefghijklmnopqrstuvwxyz~@#$%^&*-+=                   ";

const vm = new Vue({
  el: "#root",

  components: {
    "sub-actions": SubActions,
    "sub-contracts": SubContracts,
    "sub-books": SubBooks,
    "stream-newbook": StreamNewBook
  },

  data: {
    user: null,
    rdbUser: null,
    validation: '-------',
    versionString: window.navigator.appVersion,

    rawSearchString: "",
    fixedSearchString: "",

    searchTargets: [
      "BOOK",
      "SUBSP+Uni5",
      "SUBSP+UNI40",
      "SUBSP2+UNI40",
      "SUBSP+Smpl5",
      "SUBSP+Smpl40",
      "SUBSP2+Smpl40",
      "NOSP+Smpl40",
      "NOSP2+Smpl40",
      "MAP+Smpl100",
      "MAP2+Smpl100",
      "SingleWordTitle"
    ], //, 'PROP', 'JPUB', 'JTIT', 'SUBS', 'REQS'
    ctxSearchTable: "BOOK",

    ctxCloudEndpoint: "gcp-tokyo1",

    localStorage: new LSM("/search/"),

    searchPresets: DefaultSearchPresets,

    ctxSelection: null,

    showActionPalette: false,

    fetchCanceller: null,
    rawRecords: null
  },

  created: function () {
    const storedSettings = this.localStorage.getItem("searchPresets");

    if (storedSettings) {
      for (const key of Object.keys(storedSettings)) {
        console.log(key);
        this.searchPresets[key] =
          storedSettings[key] || this.searchPresets[key];
      }
    }

    this.ctxSearchTable = this.searchPresets.searchTarget || this.ctxSearchTable;
    this.rawSearchString = this.fixedSearchString = this.searchPresets.searchString;
    this.ctxCloudEndpoint = this.searchPresets.cloudEndpoint;
  },

  mounted: function () {
    this.retrieveBookList();

    firebase.auth().onAuthStateChanged(user => {
      console.log('onAuthStateChanged', user)

      this.user = user;

      if (this.user) {
        this.user.getIdTokenResult(true).then(result => {
          console.log('getIdTokenResult', result);
          this.rdbUser = result.claims.rdbUser;

          if (!this.rdbUser)
            // window.location = window.location.origin + '/login.html';
            console.log('You\'re invalid RightsDB user.')
          else
            console.log('You\'re valid RightsDB user.')
        });

      } else {
        this.user = this.rbdUser = null;

        const uiConfig = {
          callbacks: {
            signInSuccessWithAuthResult: function (authResult, redirectUrl) {
              return true;
            },
            uiShown: function () {
              // document.getElementById('loader').style.display = 'none';
            }
          },
          signInFlow: 'popup',
          signInSuccessUrl: '/',
          signInOptions: [
            {
              provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
              requireDisplayName: false,
              signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
              credentialHelper: firebaseui.auth.CredentialHelper.NONE
            }
          ]
        };

        const ui = new firebaseui.auth.AuthUI(firebase.auth());
        ui.start('#firebaseui-auth-container', uiConfig);
      }
    });


    // Make sure to set Observer after mounting target element in DOM.
    // recordPos_observer.observe(this.$refs.more_records_anchor);
  },

  watch: {
    ctxSearchTable: function (value) {
      console.log(`Context Table [${this.ctxSearchTable}]`);

      this.retrieveBookList();
    },

    ctxCloudEndpoint: function (value) {
      console.log(`Context CloudEndpoint [${this.ctxCloudEndpoint}]`);

      this.retrieveBookList();
    },

    ctxSelection: function (value) {
      this.$refs[value]
      console.log(value, this.$refs[value]);
    }
  },

  computed: {
    ctxCloudEndpointTip: function () {
      return `${CloudEndpointTips[this.ctxCloudEndpoint]} に接続中`;
    },

    processedRecords: function () {
      //return null;
      return (
        this.rawRecords &&
        this.rawRecords.map(rawRecord => {
          const tmp = { ...rawRecord };

          tmp.EvolveKwdHitsJson = JSON.parse(rawRecord.EvolveKwdHits);
          tmp.isContextSelection = (rawRecord.BookID == this.ctxSelection);

          return tmp;
        })
      );
    },

    modeHint: function () {
      return "SimplifiedEx (40/40, 16/8/16, Rich)";
    },

    querySearch: function () {
      return this.rawSearchString.toUpperCase();
    },

    loadedRecordCount: function () {
      return this.rawRecords ? this.rawRecords.length : "...in progress";
    },

    ftsRecordCount: function () {
      return this.rawRecords && this.rawRecords[0]
        ? this.rawRecords[0].fts_count
        : 0;
    },

    totalRecordCount: function () {
      return this.rawRecords && this.rawRecords[0]
        ? this.rawRecords[0].full_count
        : 0;
    },

    countIndicator: function () {
      return this.rawRecords
        ? `全ての検索語が含まれるもの ${this.ftsRecordCount} 件 / 総ヒット数 ${this.totalRecordCount} 件`
        : "Search in progress...";
    },

    searchFragments: function () {
      const fragmentsInclude = [];
      const fragmentsExclude = [];
      const fragmentsMandatory = [];

      let stack = "";
      let bModeQuote = false;
      let bModeExclude = false;

      for (const c of [...this.rawSearchString]) {
        switch (c) {
          case " ":
          case ",":
          case "　":
            if (bModeQuote) {
              stack += " ";
            } else {
              // 閉クォートの直後に空白文字があった場合など、長さゼロの文字列が determined に積まれないようにする
              if (bModeExclude) {
                stack ? fragmentsExclude.push(stack) : null;
                bModeExclude = !bModeExclude;
              } else stack ? fragmentsInclude.push(stack) : null;

              stack = "";
            }

            break;

          case "-":
            if (bModeExclude) stack += "-";
            else {
              if (stack) {
                // stack += '-';
                fragmentsInclude.push(stack);
                stack = "";
              } else bModeExclude = !bModeExclude;
            }

            break;

          case '"':
            if (bModeQuote) {
              if (bModeExclude) {
                stack ? fragmentsExclude.push(stack) : null;
                bModeExclude = !bModeExclude;
              } else {
                stack ? fragmentsInclude.push(stack) : null;
                stack ? fragmentsMandatory.push(stack) : null;
              }

              stack = "";
            }

            bModeQuote = !bModeQuote;
            break;

          default:
            stack += c;
            break;
        }
      }

      // 閉クォートなしで文字列終端に達した場合、それまでの stack 文字列を fragmentsInclude に積む
      if (bModeExclude) stack ? fragmentsExclude.push(stack) : null;
      else {
        if (bModeQuote) {
          stack ? fragmentsInclude.push(stack) : null;
          stack ? fragmentsMandatory.push(stack) : null;
        } else stack ? fragmentsInclude.push(stack) : null;
      }

      return [fragmentsInclude, fragmentsExclude, fragmentsMandatory];
    }
  },

  methods: {
    validateCall: async function () {
      this.validation = 'checking...';
      const apiEndpoint = firebase.app().functions('asia-northeast1').httpsCallable('bookDataRequest');

      const result = await apiEndpoint('Succeeded')
        .then(result => this.validation = result.data)
        .catch(e => this.validation = e);

      //result ? this.validation = result.data : null;
    },

    resetSettings: function () {
      this.localStorage.removeAllItems();

      this.ctxSearchTable = 'BOOK';
      this.rawSearchString = '';
      this.ctxCloudEndpoint = 'gcp-tokyo1';

      this.retrieveBookList();
    },

    signOut: function () {
      firebase.auth().signOut()
        .then(() => this.user = this.rdbUser = null);
    },

    toggleCloudEndpoint: function () {
      const index = CloudEndpoints.indexOf(this.ctxCloudEndpoint);
      this.ctxCloudEndpoint = CloudEndpoints[(index + 1) % CloudEndpoints.length];
    },

    isFieldHit: function (jsonData, fieldName) {
      return (
        jsonData.filter(
          entry => entry.Field.toLowerCase() === fieldName.toLowerCase()
        ).length > 0
      );
    },

    copyText: async function (srcTexft) {
      await navigator.clipboard.writeText(srcTexft);
      console.log(`Copied text ${srcTexft} to clipboard.`);
    },

    expandSubContracts: function (bookID) {
      this.$refs[`sub-contracts:${bookID}`].record_id = bookID;
      console.log(this.$refs[`sub-contracts:${bookID}`].record_id);
    },

    gotInputFocus: function () {
      document.activeElement.select();
    },

    showDetail: function (id) {
      window.location.href = `record.html?id=${id}`;
    },

    openEditor: function (id, target) {
      console.log(id);
      window.location.href = `editor.html?id=${id}&t=${target}`;
    },

    onDemandLoadExtraRecords: function () {
      console.log("onDemandLoadExtraRecords");
      this.queryParamsMailHeader.page++;
      this.wrapFetchMailHeaders();
    },

    execQuery: function () {
      this.fixedSearchString = this.rawSearchString;
      this.retrieveBookList();
    },

    resetSearch: function () {
      if (this.rawSearchString.length) {
        this.rawSearchString = this.fixedSearchString = "";
        this.retrieveBookList();
      }
    },

    retrieveBookList: async function () {
      this.searchPresets.searchTarget = this.ctxSearchTable;
      this.searchPresets.searchString = this.rawSearchString;
      this.searchPresets.cloudEndpoint = this.ctxCloudEndpoint;

      this.localStorage.setItem("searchPresets", this.searchPresets);

      console.time("SearchQuery");

      this.rawRecords = null;

      const queryParam = {
        target: this.ctxSearchTable,
        word: this.rawSearchString,
        fragments: this.searchFragments[0],
        fragmentsInclude: this.searchFragments[0],
        fragmentsExclude: this.searchFragments[1],
        fragmentsMandatory: this.searchFragments[2],
        limit: 100,
        offset: 0
      };

      console.log("Includes:", this.searchFragments[0].join(", "));
      console.log("Excludes:", this.searchFragments[1].join(", "));
      console.log("Mandatories:", this.searchFragments[2].join(", "));

      const apiRegion = {
        "gcp-tokyo1": "asia-northeast1",
        "gcp-oregon1": "us-central1",
        "gcp-devtest": "asia-northeast1"
      };

      const functionName = {
        "gcp-tokyo1": "rightsSearch_tokyo",
        "gcp-oregon1": "rightsSearch_oregon",
        "gcp-devtest": "rightsSearch_dev"
      }

      const apiEndpoint = firebase.app().functions(apiRegion[this.ctxCloudEndpoint]).httpsCallable(functionName[this.ctxCloudEndpoint]);

      const result = await apiEndpoint(queryParam)
        .catch(e => console.error(e));

      console.timeEnd("SearchQuery");

      this.rawRecords = result.data;


      // this.fetchCanceller ? this.fetchCanceller.cancel() : null;
      // this.fetchCanceller = axios.CancelToken.source();

      // const apiUri = {
      //   "gcp-tokyo1": {
      //     BOOK:
      //       "https://asia-northeast1-eajweb-158909.cloudfunctions.net/rightsSerarch_tokyo",
      //     "SUBSP+Uni5":
      //       "https://asia-northeast1-eajweb-158909.cloudfunctions.net/rightsSerarch_tokyo",
      //     "SUBSP+UNI40":
      //       "https://asia-northeast1-eajweb-158909.cloudfunctions.net/rightsSerarch_tokyo",
      //     "SUBSP2+UNI40":
      //       "https://asia-northeast1-eajweb-158909.cloudfunctions.net/rightsSerarch_tokyo",
      //     "SUBSP+Smpl5":
      //       "https://asia-northeast1-eajweb-158909.cloudfunctions.net/rightsSerarch_tokyo",
      //     "SUBSP+Smpl40":
      //       "https://asia-northeast1-eajweb-158909.cloudfunctions.net/rightsSerarch_tokyo",
      //     "SUBSP2+Smpl40":
      //       "https://asia-northeast1-eajweb-158909.cloudfunctions.net/rightsSerarch_tokyo",
      //     "NOSP+Smpl40":
      //       "https://asia-northeast1-eajweb-158909.cloudfunctions.net/rightsSerarch_tokyo",
      //     "NOSP2+Smpl40":
      //       "https://asia-northeast1-eajweb-158909.cloudfunctions.net/rightsSerarch_tokyo",
      //     "MAP+Smpl100":
      //       "https://asia-northeast1-eajweb-158909.cloudfunctions.net/rightsSerarch_tokyo",
      //     "MAP2+Smpl100":
      //       "https://asia-northeast1-eajweb-158909.cloudfunctions.net/rightsSearch_tokyo",
      //     "SingleWordTitle":
      //       "https://asia-northeast1-eajweb-158909.cloudfunctions.net/rightsSerarch_tokyo"
      //   },
      //   "gcp-oregon1": {
      //     BOOK:
      //       "https://asia-northeast1-eajweb-158909.cloudfunctions.net/evolvedSearchMappedStepSimplifiedEx_oregon",
      //     "SUBSP+Uni5":
      //       "https://asia-northeast1-eajweb-158909.cloudfunctions.net/evolvedSearchMappedStepSimplifiedEx_oregon",
      //     "SUBSP+UNI40":
      //       "https://asia-northeast1-eajweb-158909.cloudfunctions.net/evolvedSearchMappedStepSimplifiedEx_oregon",
      //     "SUBSP2+UNI40":
      //       "https://asia-northeast1-eajweb-158909.cloudfunctions.net/evolvedSearchMappedStepSimplifiedEx_oregon",
      //     "SUBSP+Smpl5":
      //       "https://asia-northeast1-eajweb-158909.cloudfunctions.net/evolvedSearchMappedStepSimplifiedEx_oregon",
      //     "SUBSP+Smpl40":
      //       "https://asia-northeast1-eajweb-158909.cloudfunctions.net/evolvedSearchMappedStepSimplifiedEx_oregon",
      //     "SUBSP2+Smpl40":
      //       "https://asia-northeast1-eajweb-158909.cloudfunctions.net/evolvedSearchMappedStepSimplifiedEx_oregon",
      //     "NOSP+Smpl40":
      //       "https://asia-northeast1-eajweb-158909.cloudfunctions.net/evolvedSearchMappedStepSimplifiedEx_oregon",
      //     "NOSP2+Smpl40":
      //       "https://asia-northeast1-eajweb-158909.cloudfunctions.net/evolvedSearchMappedStepSimplifiedEx_oregon",
      //     "MAP+Smpl100":
      //       "https://asia-northeast1-eajweb-158909.cloudfunctions.net/evolvedSearchMappedStepSimplifiedEx_oregon",
      //     "MAP2+Smpl100":
      //       "https://asia-northeast1-eajweb-158909.cloudfunctions.net/evolvedSearchMappedStepSimplifiedEx_oregon",
      //     "SingleWordTitle":
      //       "https://asia-northeast1-eajweb-158909.cloudfunctions.net/evolvedSearchMappedStepSimplifiedEx_oregon"
      //   }
      // };

      // const jsonParams = {
      //   params: queryParam,
      //   cancelToken: this.fetchCanceller.token
      // };

      // axios
      //   .post(apiUri[this.ctxCloudEndpoint][this.ctxSearchTable], queryParam, {
      //     cancelToken: this.fetchCanceller.token
      //   })
      //   .then(resp => {
      //     this.rawRecords = resp.data;
      //   })
      //   .catch(e => {
      //     if (!axios.isCancel(e)) {
      //       console.log("WebAPI GET Failed...", e);
      //       this.rawRecords = [];
      //     } else console.log("WebAPI GET Cancelled...", e);
      //   })
      //   .finally(() => console.timeEnd("SearchQuery"));
    },

    quickCallTest: async function () {
      const apiEndpoint = firebase.functions().httpsCallable('bookDataRequest');

      const result = await apiEndpoint("make a bit better, ideate!")
        .catch(e => console.error(e));

      console.log('quickCallTest', result.data);
    }
  }
});
